import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../styles/globalStyles';
import theme from '../styles/theme';

import Header from '../components/header';
import { ALink } from '../components/actions/styles';
import SEO from '../components/seo';

import * as L from '../components/layout/styles';
import * as S from '../components/about/styles';
import * as T from '../components/typography/styles';
import Pattern from '../components/pattern';
import { CVLink } from '../components/actions';

const About = ({ pageContext: { content } }) => {
  const data = content['about'];

  return (
    <ThemeProvider theme={theme.about}>
      <Fragment>
        <SEO />
        <GlobalStyle />

        <L.Page>
          <Header {...content.header} pdf={'data.pageData.frontmatter.attachments[0]'} />

          <L.Container>
            <Pattern />
            <CVLink />

            <L.ColumnLeft>
              <T.Heading>I am a Front-End Developer with a solid background in User Experiences and UI design – focusing on details, I like to make people love the product.</T.Heading>
              <T.P>Over the past two years, I was involved in several web and mobile projects, working within the teams from two to over than twenty developers. I had a chance to build the applications from scratch as well as implement new features in existing applications. I have learned how to deliver the best quality web apps with a deep understanding of business and users needs.</T.P>
              <T.P>I worked in an agile environment, where we were using the scrum methodology and cooperated closely with the client or its developers. I had experience working with React (more than four developed web applications), React Native, Ember, Handlebars, RoR environment, ES6, Redux, Jest.</T.P>

              <L.FlexContainer>
                <ALink href='https://www.linkedin.com/in/kinga-sieminiak/' target='_blank'>LinkedIn</ALink>
                <ALink href='https://github.com/kingasieminiak' target='_blank'>GitHub</ALink>
                <ALink href='https://bitbucket.org/kingasieminiak/' target='_blank'>Bitbucket</ALink>
                <ALink href='https://codepen.io/kingasieminiak/' target='_blank'>CodePen</ALink>
              </L.FlexContainer>
            </L.ColumnLeft>

            <L.ColumnRight>
              <T.Heading>I have skills in both UI/UX Design and Front-End Development. I am able to create a product from idea to implementation with special attention to make the product usable and user friendly.</T.Heading>

              <T.H4>#{data.subheadings.development}</T.H4>
              <S.List>
                {data.developmentItems.map(item => <S.ItemSmall key={item.text}>{item.text}</S.ItemSmall>)}
              </S.List>

              <T.H4>#{data.subheadings.design}</T.H4>
               <S.List>
                {data.designItems.map(item => <S.ItemSmall key={item.text}>{item.text}</S.ItemSmall>)}
              </S.List>

              <T.H4>#{data.subheadings.other}</T.H4>
              <S.List>
                {data.otherItems.map(item => <S.ItemSmall key={item.text}>{item.text}</S.ItemSmall>)}
              </S.List>
            </L.ColumnRight>
          </L.Container>
        </L.Page>
      </Fragment>
    </ThemeProvider>
  );
};

export default About;
