import styled from 'styled-components';
import mediaMin, { breakpoints } from '../../styles/mediaQueries';

export const AboutContainer = styled.section(
  {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '90rem',
    margin: '0 auto',

    [mediaMin(breakpoints.md)]: {
      flexDirection: 'row',
      height: '100vh',

      '&:after': {
        position: 'absolute',
        top: '60px',
        right:' 0',
        zIndex: '1',
        width: '50%',
        height: '1px',
        backgroundColor: '#84b1c4',
      }
    }
  },

  ({ theme }) => ({ backgroundColor: theme.background })
);

AboutContainer.Title = styled.h1(
  {
    fontSize: '2rem',
    margin: '1rem 0',

    [mediaMin(breakpoints.md)]: {
      fontSize: '3rem',
      margin: '2.5rem 0'
    },

    [mediaMin(breakpoints.lg)]: {
      fontSize: '4rem',
    },
  }
);

AboutContainer.Text = styled.p(
  {
    fontSize: '1rem',
    lineHeight: 1.4
  }
);

export const List = styled.ul(
  {
    display: 'flex',
    flexFlow: 'row wrap',
    listStyle: 'none',
    padding: 0,
    margin: 0
  }
);

export const Item = styled.li(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '.5rem 1rem',
    border: '1px solid #514e98',
    fontSize: '1rem',
    marginLeft: '-1px',
    marginTop: '-1px',
    fontFamily: 'Helvetica, Arial, sans-serif',
  }
);

export const ItemSmall = styled(Item)(
  {
    fontSize: '.8rem',
  }
);

export const ItemLarge = styled(Item)(
  {
    fontSize: '2rem',
  }
);
